import React, { useEffect, useState } from 'react';
import { getUserInfo } from '../apicalls/users';
import { Form, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { SetUser } from '../redux/usersSlice';
import {useNavigate} from 'react-router-dom';
import {showLoading, hideLoading} from '../redux/loaderSlice';



const ProtectedRoute = ({ children }) => {
    const { user } = useSelector((state) => state.users)
    const [menu, setMenu] = useState([]);
    const [collapsed, setCollapsed] = useState();

    const dispatch = useDispatch();
    const navigate = useNavigate()

    const userMenu = [
                {
                    title: 'Home',
                    paths: ['/', '/user/write-exam'],
                    icon: <i className="ri-home-line"></i>,
                    onClick: () => navigate('/')
                },
                {
                    title: 'Reports',
                    paths: ['/user/reports'],
                    icon: <i className="ri-bar-chart-line"></i>,
                    onClick: () => navigate('/user/reports')
                },
                {
                    title: 'Profile',
                    paths: ['/profile'],
                    icon: <i className="ri-user-line"></i>,
                    onClick: () => navigate('/profile')
                },
                {
                    title: 'Logout',
                    paths: ['/logout'],
                    icon: <i className="ri-logout-box-line"></i>,
                    onClick: () => {
                        localStorage.removeItem('token');
                        navigate('/login');
                     }
                },
    ]

    const adminMenu = [
        
        {
            title: 'Home',
            paths: ['/', '/user/write-exam'],
            icon: <i className="ri-home-line"></i>,
            onClick: () => navigate('/')
        },
        {
            title: 'Exams',
            paths: ['/admin/exams', '/admin/exams/add', '/admin/exams/edit/:id'],
            icon: <i className="ri-file-list-line"></i>,
            onClick: () => navigate('/admin/exams')
        },
        {
            title: 'Reports',
            paths: ['/admin/reports'],
            icon: <i className="ri-bar-chart-line"></i>,
            onClick: () => navigate('/admin/reports')
        },
        {
            title: 'Profile',
            paths: ['/profile'],
            icon: <i className="ri-user-line"></i>,
            onClick: () => navigate('/profile')
        },
        {
            title: 'Logout',
            paths: ['/logout'],
            icon: <i className="ri-logout-box-line"></i>,
            onClick: () => {
                localStorage.removeItem('token');
                navigate('/login');
             }
        },


    ]

    const getUserData = async () => {
        try {
            dispatch(showLoading());
            const response = await getUserInfo();
            dispatch(hideLoading());
            if (response.success) {
                dispatch(SetUser(response.data));
                if(response.data.isAdmin){
                   setMenu(adminMenu);
                } else {
                  setMenu(userMenu);
                }
            } else {
                message.error(response.message);
            }
        } catch (error) {
            navigate('/login');
            message.error(error.message)
            dispatch(hideLoading());
        }

    }
    useEffect(() => {
        if(localStorage.getItem('token')){
            getUserData();
        } else {
            navigate('/login');
        }
    }, []);

    const activeRoute = window.location.pathname;
    const getIsActiveOrNot = (paths) => {
       if(paths.includes(activeRoute)) {
        return true;
       } else {
        if(activeRoute.includes('/admin/exams') && paths.includes("/admin/exams")) {
            return true;
        } 
        if(activeRoute.includes('/user/write-exam') && paths.includes('/user/write-exam')) {
            return true;
        }
       }
       return false;
    }
    return (
        <div className='layout'>
            <div className='flex gap-2 w-full h-full h-100'>
                <div className='sidebar'>
                  <div className='menu'>
                      { menu.map((item, index) => {
                           return (<div className={`menu-item ${getIsActiveOrNot(item.paths) && 'active-menu-item'}`}
                               key={index} onClick={item.onClick}>
                               {item.icon}
                               {!collapsed && <span className='text-white'> {item.title} </span>}
                            </div>)
                        })
                      }
                  </div>
                </div>
                <div className='body'>
                   <div className='header flex justify-between'>
                        {!collapsed && <i class="ri-close-line text-white" onClick={() => setCollapsed(!collapsed)}></i>}
                        {collapsed && <i class="ri-menu-line text-white" onClick={() => setCollapsed(!collapsed)}></i>}

                        <h1 className='text-2xl text-white'>
                            Priya Quiz
                        </h1>
                        <div className='flex gap-1 items-center'>
                            <i class="ri-user-line text-white"></i>
                            <h1 className='text-md text-white underline'>
                            {user?.name}
                        </h1>
                        </div>
                   </div>
                   <div className='content'>
                     {children}
                   </div>
                </div>
            </div>
        </div>
    )
}

export default ProtectedRoute;