import React from 'react';


const Loader = () => {
    return (<div className='loader-parent'>
        <div className='loader'>
        </div>
    </div>)
}

export default Loader;